<template>
  <div class="mb-12">
    <SubHeader :type="$t('projectProfile.bar')" />
    <div class="container-xl px-2 py-2 mx-auto w-full my-4 flex flex-col">
      <div class="grid md:grid-cols-5 h-auto pb-10 gap-4">
        <div class="md:col-span-1">
          <SidebarProject />
        </div>
        <TransitionFade>
          <div class="md:col-span-4 h-auto space-y-3">
            <Card v-if="$verifyPermission(permissions, ['v2.client.integration.update.automapper'])">
              <template #content>
                <div class="flex flex-col w-full">
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="form-group">
                      <label for="Name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("projectProfile.name") }}</label>
                      <InputText v-model="members.email" type="email" name="email" class="p-inputtext-sm" :placeholder="$t('invite.placeholder')" />
                    </div>
                    <div class="form-group">
                      <label for="credential" class="form-label">{{ $t("team.role") }} <LabelRequired /></label>
                      <Dropdown v-model="members.rol" :options="roles" optionLabel="label" optionValue="value" dataKey="value" :placeholder="$t('select')" class="p-inputtext-sm w-full shadow-sm" />
                    </div>
                  </div>

                  <div>
                    <Button @click="addMember" class="p-button-rounded p-button-secondary" :label="$t('invite.addMember')" />
                  </div>
                </div>
              </template>
            </Card>

            <Card>
              <template #content>
                <div class="flex flex-col w-full">
                  <div class="text-gray-700 block text-sm mb-3 font-semibold">{{ $t("team.activeMembers") }} ({{ projectUsers.length || 0 }})</div>
                  <div class="grid grid-cols-1">
                    <div class="my-0">
                      <TableSkeleton class="min-w-full" v-if="!projectUsers.length" />
                      <table class="min-w-full rounded" v-if="projectUsers.length">
                        <tbody class="bg-white divide-y divide-gray-100">
                          <tr v-for="(projectUser, index) in projectUsers" :key="index">
                            <td class="pl-3 py-3">
                              <img :src="projectUser.user.avatar" class="rounded-full w-10" />
                            </td>
                            <td class="pl-3 py-3">
                              <span class="indicator inline-block bg-light-green-500"> </span>
                            </td>
                            <td class="pl-3 py-3 text-gray-500">
                              <div class="flex flex-col justify-start items-start text-sm">
                                <p class="font-semibold">{{ projectUser.user.email }}</p>
                                <p class="font-medium">{{ projectUser.user.name }}</p>
                              </div>
                            </td>
                            <td class="w-2/3 text-right px-6 py-3">
                              <span class="px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase bg-gray-200 text-gray-800">
                                {{ projectUser.role }}
                              </span>
                            </td>
                            <td v-if="$verifyPermission(permissions, ['v2.client.project.detach', 'v2.client.project.setRole'], 'some')">
                              <VerticalDotsDropdown>
                                <template v-slot:buttons>
                                  <a v-if="$verifyPermission(permissions, ['v2.client.project.setRole'])" @click.stop="modelSetRole(projectUser, index)" href="javascript:void(0);" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-600 group" role="menuitem">Change role</a>
                                  <a v-if="$verifyPermission(permissions, ['v2.client.project.detach'])" @click.stop="deleteFromProject(projectUser.user_id)" href="javascript:void(0);" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-red-100 hover:text-red-600 group" role="menuitem"> <DropdownOptionDelete /> Delete</a>
                                </template>
                              </VerticalDotsDropdown>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </template>
            </Card>

            <Card>
              <template #content>
                <div class="flex flex-col w-full">
                  <div class="text-gray-700 block text-sm mb-3 font-semibold">{{ $t("team.pendingInvitations") }} ({{ pending.length || 0 }})</div>
                  <div class="grid grid-cols-1 gap-4">
                    <TableSkeleton class="min-w-full" v-if="!projectUsers.length" />
                    <table class="min-w-full rounded" v-if="projectUsers.length">
                      <tbody class="bg-white divide-y divide-gray-100">
                        <tr v-for="(invite, userIndex) in pending" :key="userIndex">
                          <td class="pl-3 py-3">
                            <img src="@/assets/invite-pending.png" class="rounded-full w-10" />
                          </td>
                          <td class="pl-3 py-3">
                            <span class="indicator inline-block bg-gray-300" />
                          </td>
                          <td class="pl-3 py-3 text-gray-500">
                            <div class="flex flex-col justify-start items-start text-sm">
                              <p v-if="$store.state.Langs.selected.lang == 'es'" class="font-light text-gray-500">
                                Esperando que <span class="font-semibold text-black">{{ invite.email }}</span> acepte la invitación, última invitación enviada
                                <span class="font-semibold text-black">{{ $moment(invite.last_invitation_at).format("YYYY-MM-DD HH:mm") }}</span>
                              </p>
                              <p v-if="$store.state.Langs.selected.lang == 'en'" class="font-light text-gray-500">
                                Waiting for <span class="font-semibold text-black">{{ invite.email }}</span> to accept the invitation, last invitation sent on <span class="font-semibold text-black">{{ $moment(invite.last_invitation_at).format("YYYY-MM-DD HH:mm") }}</span>
                              </p>
                            </div>
                          </td>
                          <td>
                            <VerticalDotsDropdown>
                              <template v-slot:buttons>
                                <a v-if="$verifyPermission(permissions, ['v2.client.project.invite'])" @click.stop="syncInvite(invite)" href="javascript:void(0);" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-green-100 hover:text-green-800 group" role="menuitem"> <i class="pi pi-refresh mr-2" :class="syncInviteIcon == true ? 'animate-spin' : ''"></i> {{ $t("audience.syncro") }} </a>
                                <a v-if="$verifyPermission(permissions, ['v2.client.project.detach'])" @click.stop="deleteInvite(invite)" href="javascript:void(0);" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-red-100 hover:text-red-600 group" role="menuitem"> <DropdownOptionDelete /> {{ $t("audience.delete") }} </a>
                              </template>
                            </VerticalDotsDropdown>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </TransitionFade>
      </div>
    </div>
    <Dialog header="Change role" v-model:visible="model.role.show" position="center" modal :dismissableMask="true" :style="{ width: '30rem', padding: 0 }">
      <div class="flex flex-col">
        <form @submit.prevent="changeRole">
          <div class="form-group">
            <label for="credential" class="form-label">{{ $t("team.change_role") }} <LabelRequired /></label>
            <Dropdown v-model="model.role.form.role" :options="roles" optionLabel="label" optionValue="value" dataKey="value" :placeholder="$t('select')" class="p-inputtext-sm w-full shadow-sm" />
          </div>
          <div class="flex items-center justify-end space-x-3 pt-3">
            <Button @click="model.role.show = false" type="button" label="Cancel" class="p-button-light shadow" />
            <Button type="submit" label="Cambiar" :loading="model.role.form.busy" class="p-button-primary shadow" />
          </div>
        </form>
      </div>
    </Dialog>
  </div>
</template>

<script>
// App components
import SubHeader from "@/components/SubHeader"
import axiosClient from "@/config/AxiosClient"
import TransitionFade from "@/components/TransitionFade"
import SidebarProject from "@/components/SidebarProject"
import VerticalDotsDropdown from "@/components/UI/buttons/VerticalDotsDropdown"
import DropdownOptionDelete from "@/components/Dropdowns/DropdownOptionDelete"
import TableSkeleton from "@/components/UI/TableSkeleton"
import ApiRoute from "@/config/ApiRoute"
const sprintf = require("sprintf-js").sprintf

// Framework components
import Card from "primevue/card"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import Dropdown from "primevue/dropdown"
import Dialog from "primevue/dialog"
import LabelRequired from "@/components/LabelRequired"
import { mapGetters } from 'vuex'
import FormClient from "@/config/FormClient"

export default {
  name: "ProjectTeam",
  components: {
    // App components
    SubHeader,
    TransitionFade,
    SidebarProject,
    VerticalDotsDropdown,
    DropdownOptionDelete,
    TableSkeleton,

    // Framework components
    Card,
    Button,
    InputText,
    Dialog,
    Dropdown,
    LabelRequired
  },
  async created() {
    if (this.currentProject) this.getProject(this.currentProject)
    let response = await axiosClient.get(`/api/2.0/project/${this.currentProject}/roles`)
    this.roles = response.data.map(f => ({label: f.replace(/client-/g,''), value: f}))
  },
  mounted() {
    this.projectUserList()
    this.getPendingInvitations()
  },
  data: function () {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      showSpinner: false,
      members: {
        email: "",
        rol: 'client-viewer'
      },
      showSpinnerMember: false,
      syncInviteIcon: false,
      projectUsers: [],
      pending: {},
      userPendingId: {},
      indexSelect: null,
      roles: [],
      model: {
        role: {
          show: false,
          form: new FormClient({ role: null, user_id: null })
        }
      }
    }
  },
  computed: {
    ...mapGetters({permissions: 'Projects/permissionsForProject'}),
    validateInputMember() {
      return this.members.email === ""
    }
  },
  methods: {
    getProject(id) {
      this.$store.dispatch("Projects/getProject", id)
    },
    async addMember() {
      try {
        this.showSpinnerMember = true
        const res = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.currentProject}/invite`, {
          email: this.members.email,
          rol: this.members.rol
        })

        if (res.status === 200 || res.status === 204) {
          this.getPendingInvitations()
          this.$toast.success(this.$t("utils.toast.invitationSent"))
          setTimeout(() => {
            this.showSpinnerMember = false
            this.members.email = ""
          }, 500)
        }
      } catch (error) {
        this.$toast.error(this.$t("utils.toast.error"))
        console.log(error)
      } finally {
        this.showSpinnerMember = false
      }
    },
    async projectUserList() {
      let response = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.currentProject}/users`)
      if (response.status == 200) {
        this.projectUsers = response.data
        this.projectUsers.map(function(item){
          item.role = item.role.replace(/client-/g,'')

          return item
        })
      }
    },
    async getPendingInvitations() {
      let res = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.currentProject}/invitations`)
      if (res.status == 200) {
        this.pending = res.data.filter((f) => f.is_pending == 1)
      }
    },
    async syncInvite(invite) {
      try {
        this.syncInviteIcon = true
        const res = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.currentProject}/invite`, {
          email: invite.email
        })

        if (res.status === 200 || res.status === 204) {
          this.$toast.success(this.$t("utils.toast.invitationSent"))
        }
      } catch (error) {
        this.$toast.error(this.$t("utils.toast.error"))
        console.log(error)
      } finally {
        this.syncInviteIcon = false
      }
    },
    async deleteInvite(invite) {
      try {
        await axiosClient.delete(`${process.env.VUE_APP_URL_API}/api/2.0/project/invitations/${invite.id}`)
        this.$toast.success(this.$t("utils.toast.invitationDeleted"))
        this.pending = this.pending.filter((f) => f.id != invite.id)
      } catch (error) {
        this.$toast.error(this.$t("utils.toast.error"))
        console.log(error)
      }
    },
    async deleteFromProject(user_id) {
      let res = await axiosClient.put(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.currentProject}/detach/${user_id}`)
      if (res.status == 200 || res.status == 204) {
        this.$toast.success(this.$t("utils.toast.userDeleted"))
        this.projectUsers = this.projectUsers.filter((f) => f.user_id != user_id)
      }
      if (res.status === 500 || res.status === 401 || res.status === 422) {
        this.$toast.error(this.$t("utils.toast.error"))
      }
    },
    modelSetRole(projectUser, index){
      this.model.role.form.role = 'client-' + projectUser.role
      this.model.role.form.user_id = projectUser.user_id
      this.indexSelect = index
      this.model.role.show = true;
    },
    async changeRole(){
      await this.model.role.form.post(sprintf(ApiRoute.team.setRole, { project_id: this.currentProject }))
      this.projectUsers[this.indexSelect].role = this.model.role.form.role.replace(/client-/g,'')
      this.model.role.show = false
      this.$toast.success("Rol cambiado")
    }
  }
}
</script>
